import { useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

import { ANDROID, EXTENSION_CHROME, iOS } from '~/config/constants';

export const useDownloadLink = () => {
  const [walletLink, setWalletLink] = useState<string>();

  useEffect(() => {
    if (isIOS) {
      setWalletLink(iOS);
    } else if (isAndroid) {
      setWalletLink(ANDROID);
    } else {
      setWalletLink(EXTENSION_CHROME);
    }
  }, []);

  return { walletLink };
};
