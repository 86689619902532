import { useDownloadLink } from './hooks/useDownloadLink';
import useTimeout from './hooks/useTimeout';

function App() {
  const { walletLink } = useDownloadLink();

  useTimeout(
    () => {
      if (walletLink) {
        window.location.href = walletLink;
      }
    },
    walletLink ? 1000 : 0,
  );

  return (
    <div className="container">
      <h6>Please wait a moment</h6>
      <div className="loading">
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}

export default App;
